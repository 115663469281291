@font-face {
    font-family: 'PP Hatton';
    src: url('./PP\ Hatton\ Medium\ 500.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'PP Hatton';
    src: url('./PP\ Hatton\ Bold\ 700.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Garbata';
    src: url('./GarbataTrial-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

